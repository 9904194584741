import { IonAlert } from "@ionic/react";
import { UploadFileModal } from "@src/app/components/uploadFile";
import { useAppSelector } from "@src/app/store";
import { ActionType } from "@store/session";
import { getAgentUnVerifiedShifts } from "@store/shift";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const UnverifiedShiftModals = () => {
  const dispatch = useDispatch();
  const { uploadTimesheetModal } = useAppSelector((state) => state?.session?.popups ?? {});
  const { shiftDetailsForUpload, visible } = uploadTimesheetModal ?? {};

  const [reuploadAlert, setReuploadAlert] = useState<boolean>(false);

  return (
    <>
      <IonAlert
        mode="ios"
        isOpen={reuploadAlert}
        backdropDismiss={false}
        header="You reuploaded your timesheet"
        message="Our team will now verify this shift based on this new timesheet."
        buttons={[
          {
            text: "Thank you",
            handler: () => setReuploadAlert(false),
          },
        ]}
      />
      {visible && shiftDetailsForUpload && (
        <UploadFileModal
          shift={shiftDetailsForUpload}
          onCloseOrCancel={() => {
            dispatch({ type: ActionType.HIDE_UPLOAD_TIMESHEET_MODAL });
          }}
          onSuccessfulUpload={() => {
            dispatch({ type: ActionType.HIDE_UPLOAD_TIMESHEET_MODAL });
            dispatch(getAgentUnVerifiedShifts());
            const timesheetExists = (shiftDetailsForUpload?.timecard?.files?.length ?? 0) > 0;
            if (timesheetExists) {
              setReuploadAlert(true);
            }
          }}
        />
      )}
    </>
  );
};

import { DeploymentEnvironmentName, environmentConfig } from "@src/appV2/environment";
import { Deploy } from "cordova-plugin-ionic";
import { ICurrentConfig } from "cordova-plugin-ionic/dist/IonicCordova";

export const getVersionNumber = (binaryVersionName: string) => {
  const splitVersionName = binaryVersionName.split(".");
  return `${splitVersionName[0]}.${splitVersionName[1]}`;
};

export const getChannelName = (config: ICurrentConfig) => {
  const deploymentEnvironmentName = environmentConfig.REACT_APP_ENVIRONMENT_NAME;
  if (deploymentEnvironmentName === DeploymentEnvironmentName.PRODUCTION) {
    return `production-${getVersionNumber(config.binaryVersionName)}`;
  } else if (
    deploymentEnvironmentName === DeploymentEnvironmentName.STAGING ||
    deploymentEnvironmentName === DeploymentEnvironmentName.DEVELOPMENT
  ) {
    return deploymentEnvironmentName;
  }
  return "dummy";
};

export const configureDeploy = async () => {
  const config = await Deploy.getConfiguration();
  await Deploy.configure({
    appId: config.appId,
    channel: getChannelName(config),
  });
};

export const getFullVersionName = (binaryVersionName: string, otaBuildId?: string) => {
  if (!otaBuildId) {
    return binaryVersionName;
  }
  const minorVersionName = getVersionNumber(binaryVersionName);
  return `${minorVersionName}-${otaBuildId}`;
};

import { isDefined } from "@clipboard-health/util-ts";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { DeprecatedExtraWorkedBreakTimeRequestCard } from "@src/app/unverifiedShifts/extraWorkedBreakTimeRequest";
import { useExtraWorkedTimeRequests } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import { Shift } from "@src/lib/interface";
import { ActionType } from "@store/session";
import { getAgentUnVerifiedShifts } from "@store/shift";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UnverifiedShiftsListCard } from "./unverifiedShiftListCard";
import { Store } from "../store/store.model";

export function UnverifiedShiftsList(): JSX.Element {
  const { unverifiedShiftsByDate } = useSelector((store: Store) => store.shiftStore);
  const unverifiedShifts = useMemo(
    () => Object.values(unverifiedShiftsByDate).flat(),
    [unverifiedShiftsByDate]
  );

  const { data: extraWorkedTimeRequestsData } = useExtraWorkedTimeRequests();
  const extraWorkedTimeRequests = extraWorkedTimeRequestsData?.data ?? [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatchUnverifiedShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchUnverifiedShifts = () => {
    dispatch(getAgentUnVerifiedShifts());
  };
  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatchUnverifiedShifts();
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/myShifts" mode="ios" />
          </IonButtons>
          <IonTitle>Unverified Shifts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {extraWorkedTimeRequests.map((item) => {
          const shift = extraWorkedTimeRequestsData?.included.find(
            (shift) => shift.id === item.attributes.shiftId
          );

          if (!isDefined(shift) || shift.type !== "shift") {
            return null;
          }

          return (
            <IonCard key={item.id} mode="ios" button className="ion-padding no-text-transform">
              <DeprecatedExtraWorkedBreakTimeRequestCard
                shiftStart={shift.attributes.start}
                shiftEnd={shift.attributes.end}
                shiftPay={shift.attributes.pay}
                breakStart={item.attributes.start}
                breakEnd={item.attributes.end}
                timeZone={shift.attributes.facility.tmz}
                facilityUserId={item.attributes.facilityId}
                facilityName={shift.attributes.facility.name}
              />
            </IonCard>
          );
        })}
        {unverifiedShifts.map((shift) => (
          <IonCard key={shift._id} mode="ios" button className="ion-padding no-text-transform">
            <UnverifiedShiftsListCard
              reload={dispatchUnverifiedShifts}
              shift={shift}
              openOverlay={(shiftDetailsForUpload: Shift) => {
                dispatch({
                  type: ActionType.SHOW_UPLOAD_TIMESHEET_MODAL,
                  data: { shiftDetailsForUpload },
                });
              }}
            />
          </IonCard>
        ))}
      </IonContent>
    </IonPage>
  );
}

import { formatDateTime, formatTime } from "@clipboard-health/date-time";
import { useModalState } from "@clipboard-health/ui-react";
import { InternalLink } from "@clipboard-health/ui-react";
import { IonCardSubtitle, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from "@ionic/react";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { getShiftDuration } from "@src/appV2/Shifts/MyShifts/components/ShiftCard/ShiftDuration";
import { WorkedBreakPayRequestInfoDialog } from "@src/appV2/Shifts/UnverifiedShifts/WorkedBreakPayRequestInfoDialog";
import { roundUpRateToNearestCentUsingCbhRounding } from "@src/appV2/Shifts/utils/payRateCalculation";
import { roundUpHoursToTwoDecimalsUsingCbhRounding } from "@src/appV2/Shifts/utils/timeCalculation";
import { addBusinessDays, differenceInMinutes, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { informationCircleOutline } from "ionicons/icons";

export type DeprecatedExtraWorkedBreakTimeRequestCardProps = {
  shiftStart: string;
  shiftEnd: string;
  shiftPay: number;
  breakStart: string;
  breakEnd: string;
  facilityUserId?: string;
  facilityName: string;
  timeZone: string;
};

/**
 * This component is deprecated. It will be removed once LD flag 2023-11-frontend-force-rollout-my-shifts-refactored-2
 * gets 100% rollout.
 */
export function DeprecatedExtraWorkedBreakTimeRequestCard(
  props: DeprecatedExtraWorkedBreakTimeRequestCardProps
): JSX.Element {
  const {
    shiftStart,
    shiftEnd,
    timeZone,
    facilityUserId,
    facilityName,
    shiftPay,
    breakStart,
    breakEnd,
  } = props;

  const modalState = useModalState();

  const duration = getShiftDuration({ start: shiftStart, end: shiftEnd });

  const shiftStartTime = formatTime(shiftStart, { timeZone });
  const shiftEndTime = formatTime(shiftEnd, { timeZone });

  const breakTimeInMinutes = differenceInMinutes(parseISO(breakEnd), parseISO(breakStart));
  const truncatedBreakTimeInHours = roundUpHoursToTwoDecimalsUsingCbhRounding(
    breakTimeInMinutes / 60
  );
  const breakPay = roundUpRateToNearestCentUsingCbhRounding(truncatedBreakTimeInHours * shiftPay);

  const approvalDeadline = addBusinessDays(parseISO(shiftEnd), 3);

  return (
    <div className="new-flow shifts ongoing">
      <IonCardSubtitle className="title no-text-transform">
        {formatInTimeZone(new Date(shiftStart), timeZone, "EEEE, MMM dd")}
      </IonCardSubtitle>
      <IonCardSubtitle className="title no-text-transform">
        {shiftStartTime} - {shiftEndTime} ({duration})
      </IonCardSubtitle>
      <IonCardSubtitle className="title no-text-transform">
        <span className="payment-text-color">{formatDollarsAsUsd(breakPay)}</span> |{" "}
        <span className="payment-text-color-instantpay"> pending approval</span>
      </IonCardSubtitle>
      <IonGrid className="no-padding-left">
        <IonRow>
          <IonGrid>
            <IonRow className="ion-justify-content-start ion-align-items-center ion-no-padding">
              <IonCol className="facility-info-icon-unverified ion-no-padding">
                <InternalLink to={`${RootPaths.HOME}/facility/${facilityUserId}`}>
                  <IonIcon
                    className="icon"
                    style={{ paddingLeft: "11px", marginRight: 0 }}
                    icon={informationCircleOutline}
                  />
                </InternalLink>
              </IonCol>
              <IonCol className="ion-no-padding" style={{ fontSize: ".9em" }}>
                <IonLabel>
                  <h4 style={{ marginTop: "-2px" }}>{facilityName}</h4>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonRow>
        <IonRow>
          <p className="non-ip-shift-message">
            Additional shift payment pending facility approval by{" "}
            {formatDateTime(approvalDeadline, { timeZone })}.
            <IconButton
              aria-label="Show break payment info"
              onClick={() => {
                modalState.openModal();
              }}
            >
              <InfoIcon />
            </IconButton>
          </p>
        </IonRow>
      </IonGrid>
      <WorkedBreakPayRequestInfoDialog
        modalState={modalState}
        approvalDeadlineLabel={formatDateTime(approvalDeadline, { timeZone })}
      />
    </div>
  );
}

import {
  IonAlert,
  IonButton,
  IonCardSubtitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { NonIPConversionText } from "@src/app/components/shift/nonIPConversionMessage";
import { NoTimeSheetOptions } from "@src/app/components/shift/noTimeSheetOptions";
import { VerificationPreferenceDetails } from "@src/app/components/shift/verificationPreferenceDetails";
import { LicenseTag } from "@src/app/licenseManager/components/licenseTag";
import { useWorkerQualifications } from "@src/app/licenseManager/hooks/useWorkerQualifications";
import { useCbhFlags } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { InstantPayShiftDetails, UnverifiedShift } from "@src/lib/interface";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { setTimeout } from "timers";

import { FacilityDetails } from "../hcpShifts/components/facilityDetails";
import { formatRate } from "../hcpShifts/helper";
import { getNonIPAmount } from "../utils/shiftHelper";
import { pluralHours } from "../utils/strings";

const UnverifiedShiftsListCard: React.VFC<{
  shift: UnverifiedShift;
  reload: () => void;
  openOverlay: (s: UnverifiedShift) => void;
}> = ({ shift, reload, openOverlay }) => {
  const [isVerificationTimePassed, setIsVerificationTimePassed] = useState<boolean>(false);
  const [openNoTimeSheetOptions, setOpenNoTimeSheetOptions] = useState<boolean>(false);
  const [confirmUpload, setConfirmUpload] = useState<boolean>(false);
  const workerQualifications = useWorkerQualifications();
  const ldFlags = useCbhFlags();

  const { isChangedToNonInstantPay, autoClockedOut } = shift;

  const isInstantPay = shift?.isInstantPay;
  const instantPayDetails = isInstantPay ? shift.instantPayDetails : ({} as InstantPayShiftDetails);
  const timesheetArrayLength = shift?.timecard?.files?.length ?? 0;
  const timeSheetExists = timesheetArrayLength > 0 || shift?.timecardNotAvailable?.reason;
  const shiftVerificationTimeout = shift?.businessrules?.value
    ? Number(shift.businessrules.value)
    : "";
  const timeSheetUploadedDate =
    timesheetArrayLength > 0 ? moment(shift?.timecard?.createdAt).format("dddd, MMM DD") : "";
  const shiftStart = moment(shift.start);
  const shiftEnd = moment(shift.end);
  const formattedTitle = shiftStart.format("dddd, MMM DD");
  const duration = moment.duration(shiftEnd.diff(shiftStart)).subtract(30, "minutes").asHours();
  const formattedDuration = `${shiftStart.format("hh:mm A")} - ${shiftEnd.format(
    "hh:mm A"
  )} (${duration.toFixed(1)} ${pluralHours(shift)})`;
  const timerToClearSomewhere = useRef<ReturnType<typeof setTimeout>>();

  const isUploadTimeSheetButtonEnabled: boolean | undefined =
    !isVerificationTimePassed &&
    (shift.facility?.verificationPreference?.usesTimesheets ||
      shift.facility?.requireTimecardPhoto);

  useEffect(() => {
    const timeRemaining = shiftEnd
      .clone()
      .add(Number(shiftVerificationTimeout) * 60, "minutes")
      .diff(moment(), "minutes");
    setIsVerificationTimePassed(timeRemaining <= 0);
    timerToClearSomewhere.current = setTimeout(
      () => setIsVerificationTimePassed(true),
      timeRemaining * 60000
    );
    return () => {
      if (timerToClearSomewhere.current) {
        clearTimeout(timerToClearSomewhere?.current);
      }
    };
  }, [shiftEnd, shiftVerificationTimeout]);

  const openOverlayForUpload = () => {
    openOverlay(shift);
  };

  const cancelNoTimeSheet = () => {
    setOpenNoTimeSheetOptions(false);
    reload();
  };

  const openNoTimeSheet = () => {
    logEvent("Doesn’t Have Timecard");
    setOpenNoTimeSheetOptions(true);
  };

  let instantPayShiftInstructions;

  if (shift.isChangedToNonInstantPay) {
    instantPayShiftInstructions = "";
  } else if (shift.instantPayDetails?.is100InstantPayEnabled) {
    if (timeSheetExists) {
      instantPayShiftInstructions = (
        <p>
          You have been paid{" "}
          <span className="payment-text-color-instantpay">
            ${formatRate(instantPayDetails?.totalAmount)}
          </span>{" "}
          for this shift.
        </p>
      );
    } else {
      instantPayShiftInstructions = (
        <>
          <p>This is an InstantPay shift.</p>
          <p>
            Clock In at the start of your shift, record your break times, and Clock Out at the end.
          </p>
          <p>
            You’ll receive{" "}
            <span className="payment-text-color-instantpay">
              ${formatRate(instantPayDetails?.totalAmount)}
            </span>{" "}
            for this shift as soon as you’re done!
          </p>
        </>
      );
    }
  } else {
    instantPayShiftInstructions = (
      <>
        <p>
          You’ve already been paid{" "}
          <span className="payment-text-color-instantpay">
            ${formatRate(Number(instantPayDetails?.paidAmount || 0))}
          </span>{" "}
          for this shift!
        </p>
        <p>
          You’ll receive the remaining{" "}
          <span className="payment-text-color">
            ${formatRate(Number(instantPayDetails?.remaingAmount || 0))}
          </span>{" "}
          once the timesheet is reviewed and the shift is verified!
        </p>
      </>
    );
  }

  return (
    <div className="new-flow shifts ongoing">
      <IonCardSubtitle className="title no-text-transform">
        {formattedTitle}
        {!timeSheetExists &&
          !isVerificationTimePassed &&
          (shift?.facility?.verificationPreference?.usesTimesheets ||
            shift.facility?.requireTimecardPhoto) && (
            <div className="ion-float-right condensed">
              <svg
                width="24"
                height="23"
                viewBox="0 0 24 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.56348 22.9535H15.4365C16.8965 22.9535 17.6938 22.4706 18.4688 21.6058L22.5566 17.0126C23.3315 16.1366 23.5337 15.5751 23.5337 14.3846V9.47693C23.5337 8.2865 23.3315 7.72498 22.5566 6.849L18.4688 2.25574C17.6938 1.40222 16.8965 0.908081 15.4365 0.908081H8.56348C7.10352 0.908081 6.31738 1.40222 5.53125 2.25574L1.45459 6.849C0.668457 7.72498 0.466309 8.2865 0.466309 9.47693V14.3846C0.466309 15.5751 0.668457 16.1366 1.45459 17.0126L5.53125 21.6058C6.31738 22.4706 7.10352 22.9535 8.56348 22.9535ZM12 13.4974C11.3374 13.4974 10.9668 13.1268 10.9443 12.453L10.7759 7.29822C10.7534 6.61316 11.27 6.11902 11.9888 6.11902C12.7075 6.11902 13.2466 6.62439 13.2129 7.30945L13.0444 12.453C13.0107 13.1268 12.6401 13.4974 12 13.4974ZM12 17.7089C11.2588 17.7089 10.6299 17.181 10.6299 16.4286C10.6299 15.6761 11.2476 15.1483 12 15.1483C12.7524 15.1483 13.3701 15.6649 13.3701 16.4286C13.3701 17.1923 12.7524 17.7089 12 17.7089Z"
                  fill="#EB5757"
                />
              </svg>
            </div>
          )}
      </IonCardSubtitle>
      <IonCardSubtitle className="title no-text-transform">{formattedDuration}</IonCardSubtitle>
      {isInstantPay ? (
        <IonCardSubtitle className="title no-text-transform">
          <span className="payment-text-color">
            ${formatRate(Number(instantPayDetails?.totalAmount || 0))} total
          </span>{" "}
          |{" "}
          <span className="payment-text-color-instantpay">
            {!shift.instantPayDetails?.is100InstantPayEnabled
              ? `$${formatRate(Number(instantPayDetails?.paidAmount || 0))} `
              : null}
            InstantPay
          </span>
        </IonCardSubtitle>
      ) : (
        <IonCardSubtitle className="title no-text-transform">
          <span className="payment-text-color">
            ~${formatRate(getNonIPAmount(shift, ldFlags))} total
          </span>
        </IonCardSubtitle>
      )}
      {workerQualifications.length > 1 && (
        <LicenseTag agentReq={shift?.agentReq ?? ""}></LicenseTag>
      )}
      <IonGrid className="no-padding-left">
        <IonRow>
          {shift && <FacilityDetails shift={shift} showCheckInInstructions={false} />}
        </IonRow>
        <div className="non-ip-shift-message">
          <NonIPConversionText
            hasMissedClockIn={false}
            hide={!isChangedToNonInstantPay}
            isAutoClockedOut={!!autoClockedOut}
          ></NonIPConversionText>
        </div>
        {isInstantPay && <IonRow>{instantPayShiftInstructions}</IonRow>}
        {timeSheetExists &&
        !isVerificationTimePassed &&
        !isChangedToNonInstantPay &&
        timeSheetUploadedDate ? (
          <IonRow>
            <p>Timesheet added {timeSheetUploadedDate}</p>
          </IonRow>
        ) : null}
        {!isChangedToNonInstantPay ? (
          <IonRow>
            <VerificationPreferenceDetails
              timeSheetExists={timeSheetExists}
              verificationPreference={shift.facility?.verificationPreference}
            />
          </IonRow>
        ) : null}
        <IonRow className="action-btn-row">
          {!timeSheetExists && isUploadTimeSheetButtonEnabled ? (
            <IonCol size="10" className="no-padding-left">
              <IonLabel
                className="no-timesheet-select"
                onClick={(event) => {
                  event.stopPropagation();
                  openNoTimeSheet();
                }}
              >
                View other options
              </IonLabel>
              <br />
              <IonButton
                shape="round"
                className="upload-timesheet-button"
                onClick={(event) => {
                  event.stopPropagation();
                  openOverlayForUpload();
                }}
              >
                {" "}
                Upload Your Timesheet{" "}
              </IonButton>
            </IonCol>
          ) : null}
        </IonRow>
        <IonRow className="action-btn-row">
          {timeSheetExists && isUploadTimeSheetButtonEnabled ? (
            <IonCol size="12" className="no-padding-left">
              <IonButton
                fill="clear"
                className="reupload-button"
                onClick={(event) => {
                  event.stopPropagation();
                  setConfirmUpload(true);
                }}
              >
                UPLOAD NEW TIMESHEET
              </IonButton>
            </IonCol>
          ) : null}
        </IonRow>
      </IonGrid>
      <NoTimeSheetOptions
        visible={openNoTimeSheetOptions}
        setVisible={setOpenNoTimeSheetOptions}
        cancel={cancelNoTimeSheet}
        shift={shift}
        setCanShowUploadButton={() => void 0}
      />
      <IonAlert
        mode="ios"
        isOpen={confirmUpload}
        backdropDismiss={false}
        header="Upload new timesheet?"
        message="Are you sure you want to upload a new timesheet? The old one will be replaced and can't be retrieved anymore."
        buttons={[
          {
            text: "Cancel",
            handler: () => setConfirmUpload(false),
          },
          {
            text: "Reupload",
            handler: () => {
              openOverlayForUpload();
              setConfirmUpload(false);
            },
          },
        ]}
      />
    </div>
  );
};

export { UnverifiedShiftsListCard };
